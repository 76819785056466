import { SocioDetalhesPageModule } from './page/empresas/socio-detalhes/socio-detalhes.module';
import { GerirUnidadesPage } from './page/unidades/gerir-unidades/gerir-unidades.page';
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes, CanActivate } from '@angular/router';
import { AuthGuardService } from './core/auth-guard.service';
import { DiagramPage } from 'src/app/page/diagram/diagram.page';

const routes: Routes = [
    { path: '', 
        redirectTo: '/exibir-termo', 
        pathMatch: 'full', 
        canActivate: [AuthGuardService]
    },
    
    {
        path: 'login',
        loadChildren: () => import('./page/login/login.module').then( m => m.LoginPageModule),
        canActivate: [AuthGuardService]

    },
    {
        path: 'exibir-termo',
        loadChildren: () => import('./page/termo-anuencia/exibir-termo/exibir-termo.module').then( m => m.ExibirTermoPageModule),
        canActivate: [AuthGuardService]

    },
    
    {
        path: 'home',
        loadChildren: () => import('./page/home-form/home-form.module').then( m => m.HomeFormPageModule ),
        canActivate: [AuthGuardService]
    },
    {
        path: 'dashboard',
        loadChildren: () => import('./page/dashboard/dashboard.module').then(m => m.DashboardPageModule),
        canActivate: [AuthGuardService]
    },
    { 
        path: 'auditoria', 
        loadChildren: () => import('./page/auditoria/auditoria.module').then(m => m.AuditoriaPageModule),
        canActivate: [AuthGuardService]
    },
    { 
        path: 'diagram',
        component: DiagramPage,
        canActivate: [AuthGuardService]
    },
    { 
        path: 'vinculos', 
        loadChildren: () => import('./page/vinculos/vinculos-detalhes/vinculos-detalhes.module').then( m => m.VinculosDetalhesPageModule) ,
        canActivate: [AuthGuardService]
    },
    { 
        path: 'veiculos', 
        loadChildren: () => import('./page/veiculos/veiculos-detalhes/veiculos-detalhes.module').then( m => m.VeiculosDetalhesPageModule),
        canActivate: [AuthGuardService]
    },
    { 
        path: 'empresa', 
        loadChildren: () => import('./page/empresas/empresa-detalhes/empresa-detalhes.module').then( m => m.EmpresaDetalhesPageModule),
        canActivate: [AuthGuardService]
    },
    { 
        path: 'propriedade', 
        loadChildren: () => import('./page/rural/rural-detalhes/rural-detalhes.module').then( m => m.RuralDetalhesPageModule),
        canActivate: [AuthGuardService]
    },
    { 
        path: 'evolucao-patrimonial', 
        loadChildren: () => import('./page/evolucao-patrimonial/evolucao-patrimonial.module').then( m => m.EvolucaoPatrimonialPageModule ),
        canActivate: [AuthGuardService]
    },
    {   
        path: 'unidades', 
        loadChildren:() => import ('./page/unidades/gerir-unidades/gerir-unidades.module').then (m => m.GerirUnidadesPageModule), 
        canActivate: [AuthGuardService] 
    },
    { 
        path: 'providencia-situacao', 
        loadChildren: './page/providencia/gerir-providencia-situacao/gerir-providencia-situacao.module#GerirProvidenciaSituacaoPageModule',
        canActivate: [AuthGuardService] 
    },
    { 
        path: 'justificativa', 
        loadChildren: './page/justificativa/gerir-justificativa/gerir-justificativa.module#GerirJustificativaPageModule' ,
        canActivate: [AuthGuardService] 

    },
    { 
        path: 'encaminhamento', 
        loadChildren: './page/encaminhamento/gerir-encaminhamento/gerir-encaminhamento.module#GerirEncaminhamentoPageModule' ,
        canActivate: [AuthGuardService]
    },
    { 
        path: 'peticoes', 
        loadChildren: './page/peticoes/gerir-peticoes/gerir-peticoes.module#GerirPeticoesPageModule' ,
        canActivate: [AuthGuardService]
    },
    { 
        path: 'importar-empresas', 
        loadChildren: './page/importar-empresas/importar-empresas.module#ImportarEmpresasPageModule' ,
        canActivate: [AuthGuardService]
    },
    { 
        path: 'credito-tributario', 
        loadChildren: () => import('./page/credito-tributario/credito-tributario.module').then ( m => m.CreditoTributarioPageModule) ,
        canActivate: [AuthGuardService]

    },
    { 
        path: 'consultar-pta', 
        loadChildren: () => import('./page/pta/gerir-pta/gerir-pta.module').then( m => m.GerirPtaPageModule ),
        canActivate: [AuthGuardService]
    },
    { 
        path: 'notificacoes', 
        loadChildren: () => import('./page/notificacoes/notificacoes.module').then( m => m.NotificacoesPageModule ),
        canActivate: [AuthGuardService]
    },

    { 
        path: 'copiar-encaminhamento', 
        loadChildren: () => import('./page/encaminhamento/copiar-encaminhamento/copiar-encaminhamento.module').then( m => m.CopiarEncaminhamentoPageModule),
        canActivate: [AuthGuardService]
    },
    { 
        path: 'socio-detalhes', 
        loadChildren: () => import('./page/empresas/socio-detalhes/socio-detalhes.module').then( m => m.SocioDetalhesPageModule),
        canActivate:[AuthGuardService]
    },
    { 
        path: 'filiais-detalhes', 
        loadChildren: () => import('./page/empresas/filiais-detalhes/filiais-detalhes.module').then( m => m.FiliaisDetalhesPageModule),
        canActivate: [AuthGuardService]
    },
    {   path: 'atos-eventos', 
        loadChildren: ()=>import('./page/empresas/atos-eventos/atos-eventos.module').then (m=>m.AtosEventosPageModule),
          canActivate:[AuthGuardService]
    },
    { 
        path: 'socios-inativos', 
        loadChildren: ()=>import('./page/empresas/socios-inativos/socios-inativos.module').then(m=>m.SociosInativosPageModule),
        canActivate:[AuthGuardService]
    },

     { 
       path: 'parametrizacao', 
       loadChildren: ()=> import('./page/parametrizacao/parametrizacao/parametrizacao.module' ).then(m=>m.ParametrizacaoPageModule),
        canActivate:[AuthGuardService]
    },
    { 
        path: 'integracoes',
        loadChildren:()=>import('./page/integracoes/integracoes/integracoes.module').then(m=>m.IntegracoesPageModule), 
        canActivate:[AuthGuardService]
    }, 
    { 
        path: 'cadastrar-integracoes', 
        loadChildren: ()=>import('./page/integracoes/cadastrar-integracoes/cadastrar-integracoes.module').then(m=>m.CadastrarIntegracoesPageModule),
        canActivate:[AuthGuardService]
    },
    { 
        path: 'editar-integracoes', 
        loadChildren: ()=>import( './page/integracoes/editar-integracoes/editar-integracoes.module').then(m=>m.EditarIntegracoesPageModule),
         canActivate:[AuthGuardService]
    }, 
    { 
        path: 'listar-versoes', 
        loadChildren: ()=>import( './page/versionamento/listar-versoes/listar-versoes.module').then(m=>m.ListarVersoesPageModule),
        canActivate:[AuthGuardService]
    },
    {
        path: 'cadastrar-versoes',
        loadChildren: () => import('./page/versionamento/cadastrar-versoes/cadastrar-versoes.module').then(m => m.CadastrarVersoesPageModule),
        canActivate:[AuthGuardService]
    },

    {
        path: 'exibir-termo',
        loadChildren: () => import('./page/termo-anuencia/exibir-termo/exibir-termo.module').then(m => m.ExibirTermoPageModule),
        canActivate:[AuthGuardService]
    },

    { 
        path: 'editar-versoes',
        loadChildren:()=>import( './page/versionamento/editar-versoes/editar-versoes.module').then(m=>m.EditarVersoesPageModule),
        canActivate:[AuthGuardService]
    },

    { 
        path: 'relacao-inativa', 
        loadChildren:()=>import('./page/relacao-empresarial/relacao-inativa/relacao-inativa.module').then(m=>m.RelacaoInativaPageModule),
    },

    { 
        path: 'conferencia-pdf',
        loadChildren:()=>import( './page/relatorios/conferencia-pdf/conferencia-pdf.module').then(m=>m.ConferenciaPdfPageModule),
    }, 

    { 
       path: 'termo-politica', 
       loadChildren:()=>import( './page/termo-politica/termo-politica.module').then(m=>m.TermoPoliticaPageModule)
    },

    { 
        path: 'historico-pesquisa', 
        loadChildren: ()=>import('./page/historico-pesquisa/historico-pesquisa.module').then(m=>m.HistoricoPesquisaPageModule) 
    },


    { 
        path: 'controle-termo',
        loadChildren: ()=>import('./page/termo-anuencia/controle-termo/controle-termo.module').then(m=>m.ControleTermoPageModule)
    },

    { 
        path: 'gerir-carteira', 
        loadChildren: ()=>import( './page/carteira-pesquisa/gerir-carteira/gerir-carteira.module').then(m=>m.GerirCarteiraPageModule)
    }, 
    { 
        path: 'detalhes-carteira', 
        loadChildren: ()=>import( './page/carteira-pesquisa/detalhes-carteira/detalhes-carteira.module').then(m=>m.DetalhesCarteiraPageModule) 
    },
    {
        path: 'editar-carteira',
        loadChildren: ()=>import( './page/carteira-pesquisa/editar-carteira/editar-carteira.module').then(m=>m.EditarCarteiraPageModule)
    },
    {
        path: 'excluir-registro',
        loadChildren: ()=>import('./page/carteira-pesquisa/excluir-registro/excluir-registro.module').then(m=>m.ExcluirRegistroPageModule)
    },
    {
        path: 'tipo-vinculos',
        loadChildren: ()=>import('./page/tipo-vinculos/tipo-vinculos.module').then(m=>m.TipoVinculosPageModule)
    },
    {
        path: 'incluir-vinculos',
        loadChildren: ()=>import('./page/tipo-vinculos/incluir-vinculos/incluir-vinculos.module').then(m=>m.IncluirVinculosPageModule)
    },
    {
        path: 'excluir-vinculos',
        loadChildren: ()=>import('./page/tipo-vinculos/excluir-vinculos/excluir-vinculos.module').then(m=>m.ExcluirVinculosPageModule)
    },
    {
        path: 'editar-vinculos',
        loadChildren: () => import('./page/tipo-vinculos/editar-vinculos/editar-vinculos.module').then(m => m.EditarVinculosPageModule)
    },

    {
        path: 'adicionar-carteira',
        loadChildren: () => import('./page/historico-pesquisa/adicionar-carteira/adicionar-carteira.module').then(m => m.AdicionarCarteiraPageModule)

    },

    {
        path: 'condicao-socio-detalhes',
        loadChildren: () => import('./page/empresas/condicao-socio-detalhes/condicao-socio-detalhes.module').then(m => m.CondicaoSocioDetalhesPageModule)
    },

    {
        path: 'changelog',
        loadChildren: () => import('./page/changelog/changelog.module').then(m => m.ChangelogPageModule)
    },
    
   
    {
        path: 'relatorio-condicao-socios',
        loadChildren: () => import('./page/relatorios/relatorio-condicao-socios/relatorio-condicao-socios.module').then(m => m.RelatorioCondicaoSociosPageModule)
    },
    {
        path: 'carteira-pesquisado',
        loadChildren: () => import('./page/historico-pesquisa/carteira-pesquisado/carteira-pesquisado.module').then(m=>m.CarteiraPesquisadoPageModule)
    },
    { 
        path: 'condicao-socio-inativos-detalhes',
        loadChildren: () => import('./page/empresas/condicao-socio-inativos-detalhes/condicao-socio-inativos-detalhes.module').then(m=>m.CondicaoSocioInativosDetalhesPageModule)
    },
    { 
        path: 'relatorio-condicao-socios-inativos',
        loadChildren: () => import('./page/relatorios/relatorio-condicao-socios-inativos/relatorio-condicao-socios-inativos.module').then(m=>m.RelatorioCondicaoSociosInativosPageModule)
    },

    { 
        path: 'historico-anotacoes', 
        loadChildren: () => import('./page/anotacoes/historico-anotacoes/historico-anotacoes.module').then(m=>m.HistoricoAnotacoesPageModule) 
    },

    { 
        path: 'listar-anotacoes', 
        loadChildren: () => import ('./page/anotacoes/listar-anotacoes/listar-anotacoes.module').then(m=>m.ListarAnotacoesPageModule)
    },
    
    { 
        path: 'relatorio-anotacoes', 
        loadChildren: () => import ('./page/relatorios/relatorio-anotacoes/relatorio-anotacoes.module').then(m=>m.RelatorioAnotacoesPageModule) 
    },
    
    { 
        path: 'serpro-detalhes', 
        loadChildren: () => import ('./page/serpro/serpro-detalhes/serpro-detalhes.module').then(m=>m.SerproDetalhesPageModule) 
    },

    {
         path: 'pendencia-aviso', 
         loadChildren: ()=> import ('./page/gerir-aviso/pendencia-aviso/pendencia-aviso.module').then(m=>m.PendenciaAvisoPageModule),
         canActivate:[AuthGuardService]

    },

    {
        path: 'gerir-notificacoes', 
        loadChildren: ()=> import ('./page/gerir-notificacoes/gerir-notificacoes.module').then(m=>m.GerirNotificacoesPageModule),
        // canActivate:[AuthGuardService]
    },

    {
        path: 'incluir-notificacoes', 
        loadChildren: ()=> import ('./page/gerir-notificacoes/incluir-notificacoes/incluir-notificacoes.module').then(m=>m.IncluirNotificacoesPageModule),
        // canActivate:[AuthGuardService]
    },

    { 
        path: 'avisos', 
        loadChildren: ()=> import('./page/avisos/avisos.module').then(m=>m.AvisosPageModule), 
    },

    { 
        path: 'acompanhamento-especial', 
        loadChildren: ()=> import('./page/acompanhamento-especial/acompanhamento-especial.module').then(m=>m.AcompanhamentoEspecialModule), 
    },

    
    { 
        path: 'gerir-acompanhamento-especial', 
        loadChildren: ()=> import('./page/acompanhamento-especial/gerir-acompanhamento-especial/gerir-acompanhamento-especial.module').then(m=>m.GerirAcompanhamentoEspecialPageModule), 
    },

    { path: 'acesso-negado', 
        loadChildren: ()=> import ('./page/modal-verifica-acesso/modal-verifica-acesso.module').then(m=>m.ModalVerificaAcessoPageModule)
    },



//    { path: 'comunicacao-ativa-cpf-cnpj', loadChildren: './page/veiculos/comunicacao-ativa-cpf-cnpj/comunicacao-ativa-cpf-cnpj.module#ComunicacaoAtivaCpfCnpjPageModule' },


    
    // { 
    //     path: 'providencia',
    //     loadChildren: () => import('./page/providencia/gerir-providencia/gerir-providencia.module').then( m => m.GerirProvidenciaPageModule),
    //     // loadChildren: './page/providencia/gerir-providencia/gerir-providencia.module#GerirProvidenciaPageModule',
    //     canActivate: [AuthGuardService] 
    // },
    // { path: 'parametrizar-peticoes', loadChildren: './page/peticoes/parametrizar-peticoes/parametrizar-peticoes.module#ParametrizarPeticoesPageModule' },
    //Essa rota deve ser colocada abaixo de todas
    {
        path: '**',
        redirectTo: '/', //login-callback
        //canActivate: [AuthGuardService]

    },
  { path: 'incluir-acompanhamento-especial', loadChildren: './page/acompanhamento-especial/incluir-acompanhamento-especial/incluir-acompanhamento-especial.module#IncluirAcompanhamentoEspecialPageModule' },
  { path: 'excluir-acompanhamento-especial', loadChildren: './page/acompanhamento-especial/excluir-acompanhamento-especial/excluir-acompanhamento-especial.module#ExcluirAcompanhamentoEspecialPageModule' },
  { path: 'editar-acompanhamento-especial', loadChildren: './page/acompanhamento-especial/editar-acompanhamento-especial/editar-acompanhamento-especial.module#EditarAcompanhamentoEspecialPageModule' },
  { path: 'detalhes-acompanhamento-especial', loadChildren: './page/acompanhamento-especial/detalhes-acompanhamento-especial/detalhes-acompanhamento-especial.module#DetalhesAcompanhamentoEspecialPageModule' },
  { path: 'gerir-acompanhamento-especial', loadChildren: './page/acompanhamento-especial/gerir-acompanhamento-especial/gerir-acompanhamento-especial.module#GerirAcompanhamentoEspecialPageModule' },
  { path: 'modal-anotacoes-jucemg', loadChildren: './page/empresas/empresa-detalhes/modal-anotacoes-jucemg/modal-anotacoes-jucemg.module#ModalAnotacoesJucemgPageModule' },




 


];

@NgModule({
    imports: [
        RouterModule.forRoot(
            routes,
            {
                 useHash: true, 
                initialNavigation: false, 
                // enableTracing: true
                // preloadingStrategy: PreloadAllModules  
            }
        )
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {}

