import { Injectable } from '@angular/core';
import { OAuthService, OAuthStorage } from 'angular-oauth2-oidc';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AutenticacaoService } from 'src/app/core/autenticacao.service';
import { OverlayService } from 'src/app/service/overlay.service';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'lodash';
import { ServicesProvider } from '../service/services';
import { STORAGE_TYPE } from '../shared/model/local-storage-types.enum';
import { AppStorageProvider } from '../service/app-storage-provider';
import { ModalController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate{

  private allowedUrls: any = {
     ADM_SEG:['/home', '/auditoria'], //ADMINISTRADOR DE SEGURANÇA
     PRO:['/home', '/admin', '/auditoria', '/diagram', '/vinculos', '/veiculos', '/empresa', '/propriedade', '/evolucao-patrimonial', '/unidades', '/providencia-situacao', '/gerir-providencia', '/encaminhamento', '/justificativa', '/peticoes'], //PROCURADOR 
     PRO_CHE:[ '/admin', '/auditoria', '/diagram', '/vinculos', '/veiculos', '/empresa', '/propriedade', '/evolucao-patrimonial', '/unidades', '/providencia-situacao', '/gerir-providencia', '/encaminhamento', '/justificativa', '/peticoes'], //PROCURADOR-CHEFE
     SER:['/home', '/admin', '/auditoria', '/diagram', '/vinculos', '/veiculos', '/empresa', '/propriedade', '/evolucao-patrimonial', '/unidades', '/providencia-situacao', '/gerir-providencia', '/encaminhamento', '/justificativa', '/peticoes'], //SERVIDOR
     DESENV001 : [ '/admin', '/auditoria', '/diagram', '/vinculos', '/veiculos', '/empresa', '/propriedade', '/evolucao-patrimonial', '/unidades', '/providencia-situacao', '/gerir-providencia', '/encaminhamento', '/justificativa', '/peticoes'],
   };
   
   public verifica_termo: any = '' ;
   public url_atual: any = [];
   public url_termo: any =[];

  constructor(
    private oauthService: OAuthService, 
    private router: Router,
    private oauth: AutenticacaoService,
    private overlayService: OverlayService,
    private toastr: ToastrService,
    private authStorage: OAuthStorage,
    private service: ServicesProvider,
    public storage: AppStorageProvider,
    public modalController: ModalController
  ) {}


  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> |  boolean {
      // Aqui se retorna se o usuário podd acessar uma determinada rota 
      // let array
      if (this.oauthService.hasValidIdToken() || this.oauthService.hasValidAccessToken()) {
        console.log("Papéis do usuário:",state.url, this.oauth.hasRole());
        let papeis: any = this.oauth.hasRole();
        let url = (state.url.replace("/", "")).split('?');
        //console.log(url)
        this.storage.set(STORAGE_TYPE.STORAGE_PAPEL,papeis);

        return new Promise(async (resolve, reject) => {
          const loading = await this.overlayService.loading();
          this.service.requestSSC(url[0])
          .then(rs=>{ 
            console.log("Papéis necessário para acessar: ",state.url, rs);
            let lista = rs.Envelope.Body.retornaPapeisPorRecursoResponse;
             console.log(lista)
            let retorno = false;
            if(lista['papel']){
                retorno = papeis.some((p) =>{ 
                  if(_.isArray(lista['papel'])) 
                    return ( lista['papel'].some(v => v.codigoPapel == p.codigo) ) 
                  else 
                    return (lista['papel'].codigoPapel == p.codigo)
                }, this);
                //console.log("Retorno Erro : ",retorno)
              if(!retorno){
                console.log(retorno)
                this.toastr.error('Usuário sem permissão de acesso !');   
                this.router.navigate(['acesso-negado'])
              } 
              resolve(retorno);

            }else{
              resolve(false);
            }
            
          })
          .catch(er=>{
            console.log("Erro: ",er);
           // this.toastr.error('Usuário sem permissão de acesso !')
            resolve(false);
          })
          .finally(()=>{
            loading.dismiss();
          });
        });

        /*if( this.checkUrl(state.url) ) return true;
        else return false; */
      }

      if(this.storage.get(STORAGE_TYPE.STORAGE_TERMO_ACEITE)){
        this.verifica_termo = this.storage.get(STORAGE_TYPE.STORAGE_TERMO_ACEITE)
        if(this.verifica_termo != '1'){
          this.verificaTermo();
        }
      }

      this.oauth.login();
      return false;
  }  

  private async checkUrl(url: string){
    let permissoes = [];
    let papeis: any = this.oauth.hasRole();

    this.service.requestSSC(url.replace("/", ""))
    .then(rs=>{ 
      console.log(rs.Envelope.Body.retornaPapeisPorRecursoResponse['papel'] != null);
      if(rs.Envelope.Body.retornaPapeisPorRecursoResponse['papel']){
        papeis.forEach((p) =>{ 
            console.log(p);
        }, this);
        return true;
      }else{
        return false;
      }
    })
    .catch(er=>{ 
      console.log(er);
      this.toastr.error('Usuário sem permissão de acesso!')
      return false;
    });

    //Mescla todas as permissoes do usuário para ter acesso
    // papeis.forEach((rs) =>{ 
    //   permissoes = _.union(permissoes, this.allowedUrls[rs.codigo]);
    // }, this);
    // // console.log(permissoes)
    // const found = permissoes.find(u => url.startsWith(u));
    // return !!found;
    
  }

   verificaTermo(){
    this.url_termo = 'http://'+window.location.host+'/#/exibir-termo';
    this.url_atual = window.location.href;
    console.log(this.verifica_termo);
    if(this.verifica_termo != '1' && this.url_atual != this.url_termo) {
      this.router.navigate(['exibir-termo'])
    }
  }

}
